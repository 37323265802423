@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@font-face {
  font-family: "Arcadepix";
  src: url("assets/fonts/ARCADEPI.TTF");
}
// Rest css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Arcadepix", sans-serif;
  font-size: 16px;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}
// Style
.App {
  overflow: hidden;
  position: relative;
  background: #052960;
  .bg-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .container {
    position: relative;
    margin: 0 auto;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    .page-header {
      position: relative;
      .img-bg-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        img {
          height: 840px;
          @media only screen and (max-width: 1024px) {
            height: 1420px;
          }
          @media only screen and (max-width: 768px) {
            height: 1580px;
          }
        }
      }
      .wrapper {
        position: relative;
        display: flex;
        align-items: center;
        padding: 11px 0;
        @media only screen and (max-width: 1024px) {
          justify-content: center;
        }
        gap: 145px;
        @media only screen and (max-width: 968px) {
          gap: 40px;
        }
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          gap: 16px;
        }
      }
      .logo-app {
        width: 150px;
      }
      .nav-menu {
        ul {
          display: flex;
          align-items: center;
          gap: 46px;
          @media only screen and (max-width: 768px) {
            flex-direction: column;
            gap: 16px;
          }
          @media only screen and (max-width: 480px) {
            gap: 10px;
          }
          li {
            padding: 10px 0;
            &:first-child {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 10%;
                left: 70%;
                width: 87px;
                height: 2px;
                background: linear-gradient(45deg, #ff0 49%, transparent 50%);
                transform-origin: 0 100%;
                transform: scaleY(0.7) rotate(-55deg) translate(-50%, -50%);
              }
              color: #ffff00;
            }
            color: #fff;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px; /* 100% */
            letter-spacing: 0.8px;
            text-transform: uppercase;
          }
        }
      }
    }
    .page-main {
      .section-intro {
        position: relative;
        .wrapper {
          padding: 63px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0px;
          @media only screen and (max-width: 1726px) {
            gap: 0px;
          }
          @media only screen and (max-width: 1024px) {
            flex-direction: column;
            gap: 24px;
          }
          @media only screen and (max-width: 768px) {
            padding: 30px 0;
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 42px;
          @media only screen and (max-width: 1280px) {
            max-width: 750px;
          }
          @media only screen and (max-width: 1180px) {
            max-width: 700px;
          }
          .subtitle {
            color: #ebff02;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 100% */
            letter-spacing: 4px;
            text-transform: uppercase;
            position: relative;
            padding-left: 20px;
            &::after {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              content: "";
              opacity: 0.28;
              background: linear-gradient(
                90deg,
                #fff502 0.06%,
                rgba(249, 0, 0, 0) 50%
              );
              height: 54px;
              width: 100%;
            }
            @media only screen and (max-width: 1024px) {
              text-align: center;
            }
          }
          .title {
            color: #fff;
            text-shadow: -1px 5px 0px #faff00;
            font-family: Arcadepix;
            font-size: 107.84px;
            font-style: normal;
            font-weight: 400;
            line-height: 120px; /* 111.276% */
            text-transform: uppercase;
            @media only screen and (max-width: 1180px) {
              font-size: 90px;
            }
            @media only screen and (max-width: 1024px) {
              text-align: center;
            }
            @media only screen and (max-width: 768px) {
              font-size: 70px;
              line-height: normal;
            }
          }
          .des {
            color: #fff;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 36.4px; /* 140% */
            text-transform: uppercase;
            @media only screen and (max-width: 1180px) {
              font-size: 22px;
            }
            @media only screen and (max-width: 1024px) {
              text-align: center;
            }
            @media only screen and (max-width: 768px) {
              font-size: 24px;
              line-height: normal;
            }
            @media only screen and (max-width: 480px) {
              font-size: 18px;
            }
          }
          .list-btn {
            display: flex;
            align-items: center;
            gap: 20px;
            @media only screen and (max-width: 1024px) {
              justify-content: center;
            }
            a {
              position: relative;
              width: 190px;
              color: #fff;
              text-align: center;
              font-family: Barlow;
              font-size: 15px;
              font-style: normal;
              font-weight: 800;
              line-height: 26.25px; /* 175% */
              letter-spacing: 0.8px;
              text-transform: uppercase;
              @media only screen and (max-width: 480px) {
                width: 150px;
                line-height: normal; /* 175% */
                font-size: 11px;
              }
              span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .list-available {
            @media only screen and (max-width: 1024px) {
              text-align: center;
            }
            .available-title {
              color: #a7a7a7;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 109.091% */
              padding-bottom: 26px;
            }
            .row-center {
              gap: 41px;
              @media only screen and (max-width: 768px) {
                flex-wrap: wrap;
                justify-content: center;
                gap: 21px;
              }
            }
            .item-available {
              width: 186px;
              @media only screen and (max-width: 480px) {
                width: 140px;
              }
            }
          }
        }
        .img-right {
          flex-shrink: 0;
          width: 560px;
          @media only screen and (max-width: 1624px) {
            flex-shrink: unset;
          }
          @media only screen and (max-width: 636px) {
            width: auto;
          }
        }
      }
      .section-about-us {
        position: relative;
        padding: 130px 0;
        background-image: url("assets/img/img-bg-section-2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media only screen and (max-width: 1024px) {
          padding: 80px 0;
        }
        @media only screen and (max-width: 768px) {
          padding: 30px 0;
        }
        .content-top {
          position: relative;
          p {
            color: #fbfe08;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 100% */
            letter-spacing: 2px;
            text-transform: uppercase;
          }
          h3 {
            padding-top: 6px;
            color: #fff;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 54px; /* 120% */
            letter-spacing: 1px;
            text-transform: uppercase;
            position: relative;
            &::after {
              content: url("assets/img/bottom-shape.svg.png");
              height: 5px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        .content-bottom {
          display: flex;
          align-items: center;
          gap: 48px;
          padding-top: 80px;
          @media only screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
          }
          .img-left {
            img {
              width: 503px;
            }
          }
          .right {
            @media only screen and (max-width: 1024px) {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            h4 {
              color: #fbfe08;
              font-size: 30px;
              font-style: normal;
              font-weight: 400;
              line-height: 36px; /* 120% */
              text-transform: uppercase;
              @media only screen and (max-width: 1440px) {
                font-size: 28px;
                line-height: 30px;
              }
              @media only screen and (max-width: 1024px) {
                text-align: center;
              }
            }
            .des {
              padding: 24px 0 30px;
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px; /* 175% */
              @media only screen and (max-width: 1440px) {
                line-height: 24px;
              }
              @media only screen and (max-width: 1024px) {
                text-align: center;
              }
              @media only screen and (max-width: 480px) {
                line-height: 20px;
              }
            }
          }
          .list-info {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .item {
              display: flex;
              align-items: center;
              gap: 24px;
              padding: 10px 20px;
              max-width: 326px;
              height: 76px;
              border-radius: 10.019px;
              background: #fff;
              box-shadow: 0px 4.294px 10.019px 0px rgba(0, 0, 0, 0.21);
              @media only screen and (max-width: 1440px) {
                height: 45px;
                gap: 18px;
              }

              .icon {
                img {
                  width: 64px;
                  height: 64px;
                  @media only screen and (max-width: 1440px) {
                    width: 54px;
                    height: 54px;
                  }
                }
              }
              .text {
                color: #fc0b0b;
                font-size: 19.9px;
                font-style: normal;
                font-weight: 400;
                line-height: 23.574px; /* 118.464% */
                @media only screen and (max-width: 1440px) {
                  font-size: 18px;
                }
                @media only screen and (max-width: 1024px) {
                  width: 100%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .section-slider {
        background-image: url("assets/img/img-bg-section-3.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 130px 0;
        overflow-x: hidden;
        @media only screen and (max-width: 1024px) {
          padding: 80px 0;
        }
        @media only screen and (max-width: 768px) {
          padding: 30px 0;
        }
        .list-img {
          position: relative;
          display: flex;
          gap: 20px;
          padding-bottom: 69px;
          @media only screen and (max-width: 480px) {
            padding-bottom: 30px;
          }
          &::after {
            content: "";
            height: 2px;
            background: #fbfe08;
            width: 70%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
          .item-slide {
            img {
              width: 694px;
              height: 824px;
              @media only screen and (max-width: 1440px) {
                width: 554px;
                height: 684px;
              }
              @media only screen and (max-width: 1024px) {
                width: 454px;
                height: 584px;
              }
              @media only screen and (max-width: 768px) {
                width: 354px;
                height: 484px;
              }
              @media only screen and (max-width: 480px) {
                width: 254px;
                height: 384px;
              }
            }
          }
        }
      }
      .section-dexscreener {
        background-image: url("assets/img/img-bg-section-4.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 130px 0 61px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 1024px) {
          padding: 80px 0 61px;
        }
        @media only screen and (max-width: 768px) {
          padding: 30px 0;
        }
        .content-top {
          position: relative;
          padding-bottom: 80px;
          @media only screen and (max-width: 768px) {
            padding-bottom: 30px;
          }
          p {
            color: #fbfe08;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 100% */
            letter-spacing: 2px;
            text-transform: uppercase;
          }
          h3 {
            padding-top: 6px;
            color: #fff;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 54px; /* 120% */
            letter-spacing: 1px;
            text-transform: uppercase;
            position: relative;
            &::after {
              content: url("assets/img/bottom-shape.svg.png");
              height: 5px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
            @media only screen and (max-width: 480px) {
              font-size: 40px;
              line-height: normal;
            }
          }
        }
        .img-bottom {
          width: 1300px;
          @media only screen and (max-width: 1374px) {
            width: auto;
          }
        }
      }
      .section-community {
        padding: 100px 0;
        background-image: url("assets/img/img-bg-section-5.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media only screen and (max-width: 1024px) {
          padding: 80px 0;
        }
        @media only screen and (max-width: 768px) {
          padding: 30px 0;
        }
        .img-top {
          width: 575px;
          margin: 0 auto;
          @media only screen and (max-width: 578px) {
            width: auto;
          }
        }
        .content-bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 18px;
          padding-top: 18px;
          h3 {
            color: #fff;
            text-align: center;
            text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.33);
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: 66px; /* 120% */
            letter-spacing: 3px;
            text-transform: uppercase;
            span {
              color: #fbfe08;
            }
            @media only screen and (max-width: 480px) {
              font-size: 40px;
              line-height: normal;
            }
          }
          p {
            color: #fefefe;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 175% */
            letter-spacing: 0.9px;
          }
          .socials {
            position: relative;
            display: flex;
            gap: 35px;
            @media only screen and (max-width: 480px) {
              gap: 24px;
            }
            .item {
              position: relative;
              img {
                width: 155px;
                height: 52px;
              }
              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                text-align: center;
                font-family: Barlow;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 100% */
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .section-toward {
        padding: 150px 0;
        background-image: url("assets/img/img-bg-section-6.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media only screen and (max-width: 1024px) {
          padding: 80px 0;
        }
        @media only screen and (max-width: 768px) {
          padding: 30px 0;
        }
        .content {
          display: flex;
          justify-content: center;
          gap: 90px;
          @media only screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
            gap: 40px;
          }
          .left {
            max-width: 456px;
            h3 {
              color: #fff;
              font-family: Barlow;
              font-size: 55px;
              font-style: normal;
              font-weight: 800;
              line-height: 55px;
              letter-spacing: 3px;
              text-transform: uppercase;
              @media only screen and (max-width: 1024px) {
                text-align: center;
              }
              @media only screen and (max-width: 768px) {
                font-size: 40px;
                line-height: 40px;
              }
            }
            p {
              padding-top: 28px;
              color: #fff;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px; /* 175% */
              letter-spacing: 1px;
              @media only screen and (max-width: 1024px) {
                text-align: center;
              }
            }
            .img-left {
              padding-top: 24px;
              width: 456px;
              @media only screen and (max-width: 1024px) {
                width: auto;
              }
              @media only screen and (max-width: 516px) {
                width: 400px;
                margin: 0 auto;
              }
              @media only screen and (max-width: 480px) {
                width: 320px;
              }
            }
          }
          .right {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 6px;
              height: 100%;
              background: #ffff01;
              border-radius: 5px;
              left: -45px;
              top: 0;
              @media only screen and (max-width: 1024px) {
                width: 100%;
                height: 6px;
                left: 0;
                top: -20px;
              }
            }
            .list-stage {
              display: flex;
              flex-direction: column;
              gap: 20px;
              .item-stage {
                box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.21);
                padding: 45px;
                border-radius: 8px;
                @media only screen and (max-width: 1280px) {
                  padding: 24px;
                }
                @media only screen and (max-width: 480px) {
                  padding: 16px;
                }
                &:nth-child(1) {
                  background: #fe0000;
                }
                &:nth-child(2) {
                  background: #fe9902;
                }
                &:nth-child(3) {
                  background: #09f;
                }
                .row-center {
                  gap: 99px;
                  @media only screen and (max-width: 1280px) {
                    gap: 25px;
                  }
                  @media only screen and (max-width: 480px) {
                    gap: 16px;
                  }
                }
                .content {
                  display: flex;
                  flex-direction: column;
                  gap: 0;
                }
                h3 {
                  color: #fff;
                  font-family: Barlow;
                  font-size: 30px;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 30px; /* 100% */
                  text-transform: uppercase;
                  @media only screen and (max-width: 480px) {
                    font-size: 24px;
                    line-height: 24px;
                  }
                }
                ul {
                  padding-top: 22px;
                  display: flex;
                  flex-direction: column;
                  gap: 6px;
                  li {
                    color: #fff;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px; /* 175% */
                    position: relative;
                    padding-left: 37px;
                    @media only screen and (max-width: 480px) {
                      font-size: 14px;
                      line-height: 20px;
                    }
                    &::before {
                      content: "";
                      position: absolute;
                      left: 0;
                      width: 20px;
                      height: 20px;
                      top: 2px;
                      border-radius: 10px;
                      border: 2px solid #5a2323;
                      background: #ffff01;
                    }
                  }
                }
                .img-stage {
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 210px;
                }
              }
            }
          }
        }
      }
    }
    .page-footer {
      padding-top: 81px;
      background-image: url("assets/img/img-bg-footer.png");
      background-position: 90% -30px;
      background-repeat: no-repeat;
      background-size: cover;
      @media only screen and (max-width: 768px) {
        padding-top: 30px;
      }
      .img-footer {
        width: 177px;
        margin: 0 auto;
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        padding: 16px 0 30px;
      }
      p {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.25px; /* 175% */
      }
      a {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
        text-transform: uppercase;
        span {
          color: #fbfe08;
        }
      }
      .list-socials {
        padding-bottom: 23px;
        display: flex;
        gap: 24px;
        justify-content: center;
        img {
          width: 50px;
        }
      }
      .copyright {
        flex-shrink: 0;
        padding: 20px 0;
        background: #000;
        color: #fff;
        text-align: center;
        font-family: Barlow;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24.5px; /* 175% */
        text-transform: uppercase;
        span {
          color: #fc0b0b;
        }
        @media only screen and (max-width: 480px) {
          font-size: 13px;
          line-height: normal;
        }
      }
    }
  }
  .row-center {
    display: flex;
    align-items: center;
  }
}
